import React, { useState } from 'react';
import { useAuth, logout } from '../auth';
import { Box, Button, BottomNavigation, BottomNavigationAction } from '@mui/material';
import evvpLogo from '../styles/EVVAP-WHITE.png';
import archiveButton from '../styles/archiveButton.png';
import settingsButton from '../styles/settings-button.png';
import logOutButton from '../styles/logout-button.png'
import '../styles/main.css';

const NavBar = ({ showCalendar, setShowVideos, setShowSettings }) => {
  const [logged] = useAuth();
  const [mobileNavValue, setMobileNavValue] = useState(0);

  const handleMobileNavChange = (event, newValue) => {
    setMobileNavValue(newValue);
    if (newValue === 0) setShowVideos(true); // Archive
    if (newValue === 1) setShowSettings(true); // Settings
    if (newValue === 2) logout(); // Log Out
  };

  const handleButtonClick = (action) => {
    if (action === 'archive') setShowVideos(true);
    if (action === 'settings') setShowSettings(true);
    if (action === 'logout') logout();
  };

  return (
    <>
      {logged && (
        <>
          {/* Desktop View: Left Sidebar */}
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' }, // Hide on small screens
              position: 'fixed',
              top: 0,
              left: 0,
              width: '15%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '20px',
              zIndex: 2, // Ensure proper stacking
            }}
          >
            <Box
              component="img"
              sx={{
                height: 'auto',
                width: '80%',
                marginBottom: '20px',
              }}
              src={evvpLogo}
              alt="Logo"
            />
            <Button
              sx={{ width: '80%', marginBottom: '10px', backgroundColor: 'red',
                color: 'white'}}
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick('archive')}
            >
              Archive
            </Button>
            <Button
              sx={{ width: '80%', marginBottom: '10px', backgroundColor: 'red',
                color: 'white'}}
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick('settings')}
            >
              Settings
            </Button>
            <Button
              sx={{ width: '80%', backgroundColor: 'red',
                color: 'white'}}
              variant="contained"
              color="secondary"
              onClick={() => handleButtonClick('logout')}
            >
              Log Out
            </Button>
          </Box>

          {/* Mobile View: Bottom Navigation */}
          <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center' }}>
          <BottomNavigation
            sx={{
              display: { xs: 'flex', sm: 'none' }, // Show only on small screens
              position: 'relative',
              marginBottom: '10px',
              borderWidth: '1px',  // Set border thickness here
              borderStyle: 'solid', 
              borderColor: 'rgba(110, 105, 105, 1)',
              borderRadius: '16px',
              backgroundColor: 'rgba(46, 43, 43, 1)',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 2, // Ensure proper stacking
              width: '90%', // Optional: Adjust width as needed
            }}
            value={mobileNavValue}
            onChange={handleMobileNavChange}
          >
            <BottomNavigationAction style={{ borderRight:'1px', borderStyle:'solid', borderColor:'rgba(102, 102, 102, 1)'}} icon={<img src={archiveButton}/>} />
            <BottomNavigationAction style={{ borderRight:'1px', borderStyle:'solid', borderColor:'rgba(102, 102, 102, 1)'}} icon={<img src={settingsButton}/>} />
            <BottomNavigationAction icon={<img src={logOutButton}/>} />
          </BottomNavigation>
        </div>

        </>
      )}
    </>
  );
};

export default NavBar;
