import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  height: '.5rem',
  '@media (min-width:600px)': {
    height: '.5rem',
  }
});

theme.typography.h3 = {
fontSize: '11px',
'@media (min-width:600px)': {
  fontSize: '11px',

},
[theme.breakpoints.up('md')]: {
  fontSize: '1.5rem',
},
};

const Camera=({onClick, name, status, cameraID, setShow})=>{

  const [photoCover, setPhotoCover] = useState();

  useEffect(() => {
    fetch('/photos/latest_photo/' + cameraID)
      .then(res => res.json())
      .then(data => {
        setPhotoCover(data);
      })
      .catch(err => console.log(err));
  }, []);

  const onClickSettings = () => {
    setShow(true);
  }

    return(
          <Box sx={{ margin:'5px'}}>
            <Card sx={{ width:'100%', backgroundColor: "rgba(0,0,0,0)"}}>
              <CardActionArea>
              <CardMedia
                onClick={onClick}
                component="img"
                src={`data:image/jpeg;base64,${photoCover?.thumbnail_data}`}
                alt="camera"
                sx={{
                  minWidth: '190px',   // Minimum width
                  minHeight: '179px',  // Minimum height
                  width: '100%',       // Optional: Ensures it can scale, but won't go below the min size
                  height: 'auto',      // Optional: Adjusts the height to maintain aspect ratio
                  objectFit: 'cover',  // Ensures the image covers the space without distortion
                }}
              />
              <ThemeProvider theme={theme}>
              <CardActions sx={{ backgroundColor: "rgba(255,0,0,0.3)", justifyContent: 'space-between', minHeight: '298', minHeight: '29px',}} >
                <Typography variant="h3" color="common.white">{name}</Typography>
                <CircleIcon sx={{ stroke: "grey", strokeWidth: 2}} style={{ color: status ? 'green':'black'}} ></CircleIcon>
              </CardActions>
              </ThemeProvider>
              </CardActionArea>
            </Card>
          </Box>
    )
}


export default Camera;
